'use client';
import { useClientTranslation } from '@negotium/common';

import { LoadingButton } from '@mui/lab';
import {
	Avatar,
	Box,
	Container,
	FormGroup,
	Grid,
	IconButton,
	InputLabel,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import useUserFormDataClient from './useUserFormDataClient/useUserFormDataClient';
import { useNotification } from '@negotium/componentsCustomer';
import {
	MediaService,
	usersEditProfile,
	usersGetProfile,
} from '@negotium/libCustomer';
import { IProfileEdit } from '@negotium/models';
import { CameraAlt } from '@mui/icons-material';

type ProfileProps = {
	lng: 'es' | 'en';
};

const styleIconButton = {
	right: '15px',
	backgroundColor: '#f89b35',
	position: 'absolute',
	top: '150px',
	svg: {
		fill: 'white',
	},
	':hover': {
		backgroundColor: '#eee3d2 !important',
	},
};

export default function Profile({ lng }: ProfileProps) {
	const { t } = useClientTranslation(lng, ['users', 'common']);

	const [refresh, setRefresh] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);
	const { setErrorNotification, setSuccessNotification } = useNotification();
	const input = useRef<HTMLInputElement>(null);

	const initialValues = {
		userId: v4(),
		name: '',
		lastName: '',
		email: '',
		phone: '',
		ci: '',
		image: null,
	};

	useEffect(() => {
		if (refresh) {
			usersGetProfile()
				.then((result) => {
					if (result.data) {
						formik.setValues(result?.data);
					}
					setRefresh(false);
				})
				.catch((err) => {
					setErrorNotification(err.message);
				});
		}
	}, [refresh]);

	const onSubmit = async (values: IProfileEdit): Promise<void> => {
		setLoading(true);

		usersEditProfile(values)
			.then((result) => {
				if (result.status === 200) {
					setSuccessNotification(t('profileEditedSuccessfully'));
					setRefresh(true);
					setLoading(false);
				} else {
					setErrorNotification(t(`${result.message}`));
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);
				setErrorNotification(err.message);
			});
	};

	const { formik, isLoading } = useUserFormDataClient(
		initialValues,
		onSubmit,
		lng
	);

	async function uploadImage(data: FileList | null) {
		if (formik.values.userId) {
			const docs: Array<File> = [];
			if (data?.length) {
				docs.push(data[0]);
			}
			const body = {
				element: 'users',
				elementId: formik.values.userId,
			};
			MediaService('uploadImageByElement', [], body, docs)
				.then((result) => {
					if (result.docs) {
						usersEditProfile({ image: result.docs });
						formik.handleChange({
							target: {
								name: 'image',
								value: result.docs,
							},
						});
					}
				})
				.catch((err) => {
					setErrorNotification(err.result);
				});
		}
	}

	return (
		<Container maxWidth={false} disableGutters>
			<Box position='relative'>
				<Container
					maxWidth={false}
					disableGutters
					sx={{
						overflow: 'hidden',
						position: 'relative',
						height: '30vh',
						backgroundImage: `url('/static/desktopBgModal.jpg')`,
						backgroundPosition: 'bottom',
						backgroundSize: 'cover',
						borderTopLeftRadius: '15px',
						borderTopRightRadius: '15px',
					}}
				/>
				<Container
					disableGutters
					sx={{
						position: 'absolute',
						width: '200px',
						height: '200px',
						bottom: '-60px',
						left: 'calc(50% - 100px)',
						borderRadius: '50%',
					}}
				>
					<Avatar
						alt={formik.values.name || 'Remy Sharp'}
						src={
							formik.values.image?.url
								? `${process.env.NEXT_PUBLIC_MEDIA_URL_DISPLAY}${formik.values.image?.url}`
								: '/favicon/196x196.png'
						}
						sx={{ width: '200px', height: '200px' }}
					/>

					<IconButton
						onClick={() => input.current?.click()}
						sx={styleIconButton}
					>
						<CameraAlt />

						<input
							type='file'
							name='picture'
							accept='image/*'
							ref={input}
							hidden
							onChange={(e) => uploadImage(e.target.files)}
						/>
					</IconButton>
				</Container>
			</Box>

			<Paper
				elevation={2}
				sx={{
					background: (theme) => theme.palette.white?.main || 'white',
					borderBottomLeftRadius: '15px',
					borderBottomRightRadius: '15px',
				}}
			>
				<Container
					maxWidth='xl'
					sx={{
						pt: 11,
						pb: 5,
					}}
				>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography
								variant='TitleSection'
								fontSize='clamp(1rem,3vw,1.8rem)'
								textAlign='center'
								mb={2}
							>
								{t('profile', { ns: ['common'] })}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<form onSubmit={formik.handleSubmit}>
								<Grid container spacing={2}>
									<Grid item xs={12} md={6}>
										<FormGroup>
											<InputLabel
												sx={{
													color: (theme) =>
														theme.palette.secondary.main || 'black',
													fontWeight: '600',
													ml: 3,
												}}
											>
												{t('name')}
											</InputLabel>
											<TextField
												color='secondary'
												id='name'
												placeholder='Ej. John'
												fullWidth={false}
												margin='dense'
												name='name'
												value={formik.values.name}
												onChange={(e) => formik.handleChange(e)}
												onBlur={formik.handleBlur}
												error={
													formik.touched.name && Boolean(formik.errors.name)
												}
												helperText={formik.touched.name && formik.errors.name}
												sx={{
													borderRadius: 4,
													'& .MuiOutlinedInput-notchedOutline': {
														borderColor: (theme) => theme.palette.primary.dark,
														borderWidth: 2,
													},
													'& .MuiInputBase-input': {
														pl: `25px !important`,
													},
													'& > *': {
														borderRadius: `16px !important`,
													},
												}}
											/>
										</FormGroup>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormGroup>
											<InputLabel
												sx={{
													color: (theme) =>
														theme.palette.secondary.main || 'black',
													fontWeight: '600',
													ml: 3,
												}}
											>
												{t('lastName')}
											</InputLabel>
											<TextField
												color='secondary'
												id='lastName'
												placeholder='Ej. Doe'
												fullWidth={false}
												margin='dense'
												name='lastName'
												value={formik.values.lastName}
												onChange={(e) => formik.handleChange(e)}
												onBlur={formik.handleBlur}
												error={
													formik.touched.lastName &&
													Boolean(formik.errors.lastName)
												}
												helperText={
													formik.touched.lastName && formik.errors.lastName
												}
												sx={{
													borderRadius: 4,
													'& .MuiOutlinedInput-notchedOutline': {
														borderColor: (theme) => theme.palette.primary.dark,
														borderWidth: 2,
													},
													'& .MuiInputBase-input': {
														pl: `25px !important`,
													},
													'& > *': {
														borderRadius: `16px !important`,
													},
												}}
											/>
										</FormGroup>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormGroup>
											<InputLabel
												color='secondary'
												sx={{
													color: (theme) =>
														theme.palette.secondary.main || 'black',
													fontWeight: '600',
													ml: 3,
												}}
											>
												{t('email')}
											</InputLabel>
											<TextField
												color='secondary'
												id='email'
												placeholder='user@NEXT_PUBLIC_DOMAIN.xxx'
												fullWidth={false}
												margin='dense'
												name='email'
												value={formik.values.email}
												disabled
												sx={{
													borderRadius: 4,
													'& .MuiOutlinedInput-notchedOutline': {
														borderColor: (theme) => theme.palette.primary.dark,
														borderWidth: 2,
													},
													'& .MuiInputBase-input': {
														pl: `25px !important`,
													},
													'& > *': {
														borderRadius: `16px !important`,
													},
												}}
											/>
										</FormGroup>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormGroup>
											<InputLabel
												color='secondary'
												sx={{
													color: (theme) =>
														theme.palette.secondary.main || 'black',
													fontWeight: '600',
													ml: 3,
												}}
											>
												{t('phone')}
											</InputLabel>
											<TextField
												color='secondary'
												id='phone'
												placeholder=''
												fullWidth={false}
												margin='dense'
												name='phone'
												value={formik.values.phone}
												onChange={(e) => formik.handleChange(e)}
												onBlur={formik.handleBlur}
												error={
													formik.touched.phone && Boolean(formik.errors.phone)
												}
												helperText={formik.touched.phone && formik.errors.phone}
												sx={{
													borderRadius: 4,
													'& .MuiOutlinedInput-notchedOutline': {
														borderColor: (theme) => theme.palette.primary.dark,
														borderWidth: 2,
													},
													'& .MuiInputBase-input': {
														pl: `25px !important`,
													},
													'& > *': {
														borderRadius: `16px !important`,
													},
												}}
											/>
										</FormGroup>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormGroup>
											<InputLabel
												color='secondary'
												sx={{
													color: (theme) =>
														theme.palette.secondary.main || 'black',
													fontWeight: '600',
													ml: 3,
												}}
											>
												{t('ci')}
											</InputLabel>
											<TextField
												color='secondary'
												id='ci'
												placeholder=''
												fullWidth={false}
												margin='dense'
												name='ci'
												value={formik.values.ci}
												onChange={(e) => formik.handleChange(e)}
												onBlur={formik.handleBlur}
												error={formik.touched.ci && Boolean(formik.errors.ci)}
												helperText={formik.touched.ci && formik.errors.ci}
												sx={{
													borderRadius: 4,
													'& .MuiOutlinedInput-notchedOutline': {
														borderColor: (theme) => theme.palette.primary.dark,
														borderWidth: 2,
													},
													'& .MuiInputBase-input': {
														pl: `25px !important`,
													},
													'& > *': {
														borderRadius: `16px !important`,
													},
												}}
											/>
										</FormGroup>
									</Grid>
									<Grid item xs={12} display='flex' justifyContent='center'>
										<LoadingButton
											fullWidth
											type='submit'
											autoFocus
											loading={loading || isLoading}
											disabled={loading || isLoading}
											loadingPosition='end'
											title={t('updated')}
											variant='ButtonAcceptDialog'
											sx={{
												mt: 1.5,
											}}
											aria-disabled={isLoading || loading}
										>
											{t('updated')}
										</LoadingButton>
									</Grid>
								</Grid>
							</form>
						</Grid>
					</Grid>
				</Container>
			</Paper>
		</Container>
	);
}
